import React from "react";

interface IProgressBarProps {
  bgColor: string;
  completed: any;
}

export const ProgressBar = (props: IProgressBarProps) => {
  const { bgColor, completed } = props;
  const fill = {
    width: `${completed}%`,
    backgroundColor: "#007934",
    borderRadius: "1000px",
    boxShadow: "0px 3px 27px #007934",
    height: "100%",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "750px",
        width: "100%",
        height: "300px",
      }}
    >
      <div
        style={{
          height: "10px",
          width: "100%",
          borderRadius: "100px",
          marginTop: "40px",
        }}
      >
        <div style={fill} />
        <div
          style={{
            textAlign: "center",
            color: "#007934",
            marginTop: "30px",
            fontSize: "50px",
            fontWeight: "bold",
          }}
        >{`${completed}%`}</div>
      </div>
    </div>
  );
};
export default ProgressBar;
