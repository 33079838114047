import React, { useEffect } from "react";
import "./App.css";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ProgressBar from "./Components/ProgressBar/ProgressBar";

function useQuery() {
  const { search } = useLocation();
  let params = queryString.parse(search);

  return params;
}

function App() {
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
    requestPointerLock,
  } = useUnityContext({
    loaderUrl:
      "https://srvs20.landrocker.io/LRAM/AlefIO/Build/Web_Brotli_Alef.loader.js",
    dataUrl:
      "https://srvs20.landrocker.io/LRAM/AlefIO/Build/Web_Brotli_Alef.data.br",
    frameworkUrl:
      "https://srvs20.landrocker.io/LRAM/AlefIO/Build/Web_Brotli_Alef.framework.js.br",
    codeUrl:
      "https://srvs20.landrocker.io/LRAM/AlefIO/Build/Web_Brotli_Alef.wasm.br",
  });

  let query = useQuery();

  function UnityGetTokenReact() {
    if (isLoaded === true) {
      sendMessage(
        "ReactManager",
        "onGetTokenReact",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjJkMjMzMjc2LWI3ZTYtNDZhOS1iYzE0LWRmZmFmNWJiYWYxZCIsInVzZXJfbmFtZSI6InRlc3QiLCJmdWxsX25hbWUiOiIiLCJlbWFpbCI6InNhamphZHBsZDE5OThAZ21haWwuY29tIiwicGhvbmUiOiIiLCJyb2xlIjoiYWRtaW4iLCJhdmF0YXIiOiIiLCJ3YWxsZXQiOiIiLCJiaXJ0aF9kYXRlIjoiMDAwMS0wMS0wMSIsImV4cCI6MTY4NzcwMjk4NH0.YrnIzn7N7PaNKM994vCHyuljQNHwvADvHSuJuzvmsmE"
      );
      sendMessage(
        "ReactManager",
        "onGetRefreshTokenReact",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjJkMjMzMjc2LWI3ZTYtNDZhOS1iYzE0LWRmZmFmNWJiYWYxZCIsImV4cCI6MTY5Mjg4MzM4NH0.Fg5wyJL-FPS4sJNvLocfz94lua1HOcE1ioZ76jhigME"
      );
    }
  }
  useEffect(() => {
    //@ts-ignore
    addEventListener("UnityGetTokenReact", UnityGetTokenReact);
    return () => {
      //@ts-ignore
      removeEventListener("UnityGetTokenReact", UnityGetTokenReact);
    };
  }, [requestPointerLock]);

  useEffect(() => {
    UnityGetTokenReact();
  }, [isLoaded]);

  const loadingPercentage = Math.round(loadingProgression * 100);

  return (
    <div className="App">
      {isLoaded === false && (
        <div
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <img
              src="/image/Alef.png"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              alt="alef"
            />
            <div
              style={{
                position: "absolute",
                bottom: "50px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ProgressBar bgColor={"#007934"} completed={loadingPercentage} />
            </div>
          </div>
        </div>
      )}
      <div id="unity-container">
        <Unity
          unityProvider={unityProvider}
          style={{ width: "100%", height: "100vh", borderRadius: "8px" }}
        />
      </div>
    </div>
  );
}

export default App;
